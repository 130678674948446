[{"employee_id":1,"full_name":"Gaelan McCarrell","job_title":"Administrative Officer","department":"Support","salary":142226.29},
{"employee_id":2,"full_name":"Bobbee Teek","job_title":"Chief Design Engineer","department":"Training","salary":30164.74},
{"employee_id":3,"full_name":"Wendell Hurche","job_title":"Senior Quality Engineer","department":"Accounting","salary":128381.79},
{"employee_id":4,"full_name":"Debbie Awdry","job_title":"Marketing Manager","department":"Research and Development","salary":40829.88},
{"employee_id":5,"full_name":"Candie Mangeney","job_title":"Desktop Support Technician","department":"Marketing","salary":147176.58},
{"employee_id":6,"full_name":"Dollie Rubinowitz","job_title":"Health Coach II","department":"Engineering","salary":65355.62},
{"employee_id":7,"full_name":"Savina Summerlad","job_title":"Accounting Assistant I","department":"Marketing","salary":133188.38},
{"employee_id":8,"full_name":"Stirling Fries","job_title":"Accounting Assistant II","department":"Marketing","salary":121391.33},
{"employee_id":9,"full_name":"Candra Tellenbroker","job_title":"Food Chemist","department":"Research and Development","salary":65954.14},
{"employee_id":10,"full_name":"Zebadiah Vaar","job_title":"Food Chemist","department":"Research and Development","salary":135748.72},
{"employee_id":11,"full_name":"Gretna Dunkerton","job_title":"Administrative Assistant III","department":"Marketing","salary":48565.3},
{"employee_id":12,"full_name":"Xenia Cradock","job_title":"Engineer III","department":"Accounting","salary":82981.6},
{"employee_id":13,"full_name":"Evelin Szymonwicz","job_title":"Paralegal","department":"Sales","salary":115817.84},
{"employee_id":14,"full_name":"Jarvis McComb","job_title":"Statistician II","department":"Marketing","salary":120653.61},
{"employee_id":15,"full_name":"Isidoro Fearnside","job_title":"Data Coordinator","department":"Human Resources","salary":125612.67},
{"employee_id":16,"full_name":"Chickie Reaveley","job_title":"Software Test Engineer I","department":"Marketing","salary":63760.82},
{"employee_id":17,"full_name":"Delia Skirvin","job_title":"Mechanical Systems Engineer","department":"Product Management","salary":79269.83},
{"employee_id":18,"full_name":"Rebe Viney","job_title":"Environmental Specialist","department":"Business Development","salary":84432.45},
{"employee_id":19,"full_name":"Hetti Copley","job_title":"GIS Technical Architect","department":"Accounting","salary":137961.19},
{"employee_id":20,"full_name":"Roobbie Ingry","job_title":"Help Desk Technician","department":"Human Resources","salary":63540.18}]